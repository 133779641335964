import CircularProgress from "@mui/material/CircularProgress";
import {useEffect} from "react";
import userService from "@services/userService";
import {LocalStorageKey} from "@appTypes/localstorage";

const PaymentResolver = () => {
  useEffect(() => {
    const paymentObjectUid = localStorage.getItem(
      LocalStorageKey.paymentUid,
    );

    paymentObjectUid &&
      userService.getPaymentStatus(paymentObjectUid).then((res) => {
        const targetLink = res.data.paymentLink;
        window.location.assign(targetLink);
      });
  }, []);

  return <CircularProgress />;
};

export default PaymentResolver;
