import {privateApi, publicApi} from "./api";
import {IUser} from "@app/store/interfaces/user";
import {isMobile} from "@utils/detections";
import {IMystery} from "@app/store/interfaces/mystery";
import {PaymentProvider} from "../appTypes/payments";
import {TipsSize} from "@app/store/interfaces/tips";
import {
  IVotesItem,
  votesDetailMock,
  votesMockData,
} from "@app/store/interfaces/votes";
import {ICreateOnlineGift} from "@services/type";
import {AxiosResponse} from "axios";
import {UserLandingDataParams} from "@pages/user-landing/v3/type";

const origin = window.location.origin;

interface IContentsByUserIdParams {
  userId: string;
  page: number;
  contentId?: string;
}

const commonUserService = {
  shareProfile: async (username: string) => {
    const url = `${origin}/${username}`;

    if (window?.navigator?.share && isMobile()) {
      return await window.navigator.share({
        url,
      });
    } else {
      await window?.navigator?.clipboard?.writeText?.(url);
      return true;
    }
  },
  requestApproveProfile: async () => {
    return await privateApi.post("/user/approve", {});
  },
  deleteProfile: async () => {
    return await privateApi.delete("/user");
  },
  reportProfile: async (data: string) => {
    try {
      // const response = await privateApi.post("/user/report", {data});
      const response = {status: 200, data: {data}};
      console.log("Profile reported:", response);
    } catch (error) {
      console.error("Error reporting profile:", error);
    }
  },
  uploadCurrentProfileAvatar: async (formData: any) => {
    return await privateApi.post("/user/info/avatar", formData);
  },
  uploadCurrentProfileBgAvatar: async (formData: any) => {
    return await privateApi.post("/user/info/image-profile", formData);
  },
  uploadCurrentProfileAudio: async (formData: any) => {
    return await privateApi.post("/user/info/audio-profile", formData);
  },
  deleteCurrentProfileAudio: async () => {
    return await privateApi.delete("/user/info/audio-profile");
  },
  updateCurrentProfileInfo: async (data: Partial<IUser>) => {
    return await privateApi.put("/user/info/main", data);
  },
  updateSlogan: async (slogan: string) => {
    return await privateApi.put("/user/info/slogan", {slogan});
  },
  updateCurrentProfileCountry: async (data: {country: string}) => {
    return await privateApi.put("/user/info/geo", data);
  },
  updateCurrentProfileLanguage: async (data: {language: string}) => {
    return await privateApi.put("/user/info/language", data);
  },
  startChat: async (formData: any) => {
    return await privateApi.post("/messages/start-chat", formData);
  },
  getOnlineUsers: async () => {
    return await privateApi.get("/users/approved/online");
  },
  getUserProducts: async (userId: string) => {
    return await privateApi.get(`/user/${userId}/products`);
  },
};

const contentsUserService = {
  getProfileContentsAll: async (params: IContentsByUserIdParams) => {
    const {userId, contentId, page} = params;

    let url = `/user/${userId}/contents/all?page=${page}`;

    if (contentId && page === 1) {
      url = `${url}&c_uid=${contentId}`;
    }

    return await privateApi.get(url);
  },
  getProfileContentsActive: async (id: string, page = 1) => {
    return await privateApi.get(`/user/${id}/contents/active?page=${page}`);
  },
};

const dataUserService = {
  getCurrentProfile: async (personalUid: any) => {
    return await privateApi.get(
      `/user/info${personalUid ? `?p_uid=${personalUid}` : ""}`,
    );
  },
  getProfileById: async (userId: string) => {
    return await privateApi.get(`/user/${userId}`);
  },
  getUserLandingData: async (params: UserLandingDataParams) => {
    let url = `/user/page/${params.username}`;
    const queryParams = [];

    if (params.id) queryParams.push(`c_uid=${params.id}`);
    if (params.personalUid) queryParams.push(`p_uid=${params.personalUid}`);
    if (params.action) queryParams.push(`action=${params.action}`);

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    return await publicApi.get(url);
  },
  getCurrentProfileMain: async () => {
    return await privateApi.get("/user/info/main");
  },
  getCurrentProfilePayments: async (page: number) => {
    return await privateApi.get(`/payments?page=${page}`);
  },
  getPaymentStatus: async (paymentUid: string) => {
    return await privateApi.get(`/payment/status/${paymentUid}`);
  },
};

const mysteryUserService = {
  // TODO: update media appTypes
  getMysteryPrices: async () => {
    return await privateApi.get("/mystery-boxes/prices");
  },
  createMystery: async (description: string, type: string, price: number) => {
    return await privateApi.post("/mystery-box", {
      description,
      type,
      price,
    });
  },
  getMysteryById: async (id: string, personalUid?: string) => {
    return await privateApi.get(
      `/mystery-box/${id}${personalUid ? `?p_uid=${personalUid}` : ""}`,
    );
  },
  buyMysteryBox: async (
    id: string,
    contentId?: string,
    paymentProvider: PaymentProvider = "stripe",
  ) => {
    return await privateApi.post(`/mystery-box/${id}/buy`, {
      content_uid: contentId ? contentId : null,
      paymentProvider,
    });
  },
  updateMystery: async (id: string, data: Partial<IMystery>) => {
    return await privateApi.put(`/mystery-box/${id}`, data);
  },
  deleteMystery: async (id: string) => {
    return await privateApi.delete(`/mystery-box/${id}`);
  },
  archiveMystery: async (id: string) => {
    return await privateApi.post(`/mystery-box/${id}/archive`, {});
  },
  unarchiveMystery: async (id: string) => {
    return await privateApi.post(`/mystery-box/${id}/unarchive`, {});
  },
  uploadMediaMystery: async (id: string, formData: any) => {
    return await privateApi.post(`/mystery-box/${id}/media`, formData);
  },
  deleteMediaMystery: async (id: string) => {
    return await privateApi.delete(`/mystery-box/media/${id}`);
  },
  getMysteryList: async (page: number) => {
    return await privateApi.get(`/mystery-boxes?page=${page}`);
  },
  getMysteryListByUserId: async (userId: string, page: number) => {
    return await privateApi.get(`/mystery-boxes/${userId}?page=${page}`);
  },
  getPersonalMysteryBoxUid: async (id: string, buyerUid: string) => {
    return await privateApi.get(`/mystery-box/${id}/share/${buyerUid}`);
  },
  unpackMysteryBox: async (chatId: string, mysteryBoxId: string) => {
    return await privateApi.post(`/chats/${chatId}/unpack/${mysteryBoxId}`, {});
  },
  saveFirebaseToken: async (
    token: string,
    platform: string | undefined,
    agent: string | undefined,
  ) => {
    return await privateApi.post(`/user/push/token`, {token, platform, agent});
  },
  follow: async (id: string) => {
    return await privateApi.post(`/user/${id}/follow`, {});
  },
  unfollow: async (id: string) => {
    return await privateApi.post(`/user/${id}/unfollow`, {});
  },
  getFollowers: async (id: string, page: number) => {
    return await privateApi.get(`/user/${id}/followers?page=${page}`, {});
  },
  getFollowing: async (id: string, page: number) => {
    return await privateApi.get(`/user/${id}/following?page=${page}`, {});
  },
  getViewers: async (id: string, page: number) => {
    return await privateApi.get(`/user/${id}/viewers?page=${page}`, {});
  },
  removeFollower: async (id: string) => {
    return await privateApi.delete(`/user/${id}/remove-follower`, {});
  },
};

const chatSellTipsUserService = {
  buyTips: async (id: string, paymentProvider: PaymentProvider = "stripe") => {
    return await privateApi.post(`/tips/${id}/buy`, {paymentProvider});
  },
  sendTips: async (chatId: string, tipsId: string) => {
    return await privateApi.post(`/chats/${chatId}/tips/${tipsId}`, {});
  },
  getTipsPrices: async () => {
    return await privateApi.get(`/tips/prices`);
  },
  uploadTipsMedia: async (formData: any, tipsType: TipsSize) => {
    return await privateApi.post(`/tips/media/${tipsType}`, formData);
  },
  createTips: async (options: any) => {
    return await privateApi.post(`/tips`, options);
  },
  getTipsById: async (id: string, personalUid?: string) => {
    return await privateApi.get(
      `/tips/${id}${personalUid ? `?p_uid=${personalUid}` : ""}`,
    );
  },
};

const chatSellCustomTipsUserService = {
  buyCustomTips: async (
    id: string,
    paymentProvider: PaymentProvider = "stripe",
  ) => {
    return await privateApi.post(`/custom-tips/${id}/buy`, {paymentProvider});
  },
  sendCustomTips: async (chatId: string, tipsId: string) => {
    return await privateApi.post(`/chats/${chatId}/custom-tips/${tipsId}`, {});
  },
  getCustomTips: async (recipientUid: string, page = 1) => {
    return privateApi.get(`/custom-tips/user/${recipientUid}?page=${page}`);
  },
  getCustomTipsPrices: async () => {
    return await privateApi.get(`/custom-tips/prices`);
  },
  uploadCustomTipsMedia: async (formData: any, uid?: string) => {
    return await privateApi.post(`/custom-tips/media`, formData);
  },
  createCustomTips: async (options: any) => {
    return await privateApi.post(`/custom-tips`, options);
  },
  createCustomTipsByUser: async (options: any) => {
    return await privateApi.post(`/custom-tips/user`, options);
  },
  updateCustomTips: async (options: any, customTipsUid: string) => {
    return await privateApi.put(`/custom-tips/${customTipsUid}`, options);
  },
  getCustomTipsById: async (id: string) => {
    return await privateApi.get(`/custom-tips/${id}`);
  },
  unpackCustomTips: async (chatId: string, customTipsUid: string) => {
    return await privateApi.post(
      `/chats/${chatId}/unpack/custom-tips/${customTipsUid}`,
      {},
    );
  },
};

const chatSellMysteryBoxUserService = {
  shareMysteryBox: async (id: string) => {
    const url = `${origin}/mystery-boxes/${id}`;
    await window?.navigator?.clipboard?.writeText?.(url);
    return true;
  },
  sendMysteryBox: async (chatId: string, mysteryBoxId: string) => {
    return await privateApi.post(
      `/chats/${chatId}/mystery-box/${mysteryBoxId}`,
      {},
    );
  },
};

const chatSellRealGiftsUserService = {
  buyRealGift: async (
    id: string,
    paymentProvider: PaymentProvider = "stripe",
  ) => {
    return await privateApi.post(`/real-gifts/donate/${id}/buy`, {
      paymentProvider,
    });
  },
  sendRealGifts: async (chatId: string, realGiftId: string) => {
    return await privateApi.post(
      `/chats/${chatId}/real-gift-donate/${realGiftId}`,
      {},
    );
  },
  getRealGifts: async (page = 1) => {
    return privateApi.get(`/real-gifts?page=${page}`);
  },
  createRealGiftDonate: async (id: string, options: any) => {
    return await privateApi.post(`/real-gifts/${id}/donate`, options);
  },
  getRealGiftDonateById: async (id: string, personalUid?: string) => {
    return await privateApi.get(
      `/real-gifts/donate/${id}${personalUid ? `?p_uid=${personalUid}` : ""}`,
    );
  },
  getRealGiftPrices: async () => {
    return await privateApi.get(`/real-gifts/prices`);
  },
  uploadRealGiftsMedia: async (formData: any) => {
    return await privateApi.post(`/real-gifts/media`, formData);
  },
  createRealGift: async (options: any) => {
    return await privateApi.post(`/real-gifts`, options);
  },
  updateRealGift: async (id: string, options: any) => {
    return await privateApi.put(`/real-gifts/${id}`, options);
  },
};

const chatSellOnlineGiftsUserService = {
  buyOnlineGift: async (
    id: string,
    paymentProvider: PaymentProvider = "stripe",
  ) => {
    return await privateApi.post(`/online-gifts/${id}/buy`, {
      paymentProvider,
    });
  },
  createOnlineGift: async (options: ICreateOnlineGift) => {
    return await privateApi.post(`/online-gifts`, options);
  },
  getOnlineGifts: async (page = 1) => {
    return privateApi.get(`/online-gifts/catalog?page=${page}`);
  },
  sendOnlineGifts: async (chatId: string, onlineGiftId: string) => {
    return await privateApi.post(
      `/chats/${chatId}/online-gift/${onlineGiftId}`,
      {},
    );
  },
  getOnlineGiftAnimation: async (onlineGiftId: string) => {
    return privateApi.get(`/online-gifts/${onlineGiftId}/animation`);
  },
  getOnlineGiftById: async (id: string, personalUid?: string) => {
    return await privateApi.get(
      `/online-gifts/${id}${personalUid ? `?p_uid=${personalUid}` : ""}`,
    );
  },
};

const chatSellScriptsUserService = {};

const chatSellVotesUserService = {
  shareVotes: async (id: string) => {},
  getVotes: async () => {
    return new Promise<IVotesItem[]>((resolve, reject) => {
      resolve(votesMockData);
    });
  },
  sendVotes: async (chatId: string, votesId: string) => {
    return await privateApi.post(`/chats/${chatId}/votes/${votesId}`, {});
  },
  getVotesById: async (id: string) => {
    if (id === "01HDRC59DGYS83QYCR767Z9KVV") {
      return new Promise<IVotesItem>((resolve) => resolve(votesDetailMock));
    } else {
      throw new Error("Vote ID not found");
    }
  },
  buyVotes: async (id: string, paymentProvider: PaymentProvider = "stripe") => {
    return await privateApi.post(`/votes/${id}/buy`, {paymentProvider});
  },
};

const chatHotContentUserService = {
  startHotContentChat: async (formData: {
    uid: string;
  }): Promise<
    AxiosResponse<{
      channelId: string;
    }>
  > => {
    return await privateApi.post("/messages/start-chat/hot-content", formData);
  },
  unpackHotContentImage: async (chatId: string, hotContentId: string) => {
    return await privateApi.post(
      `/chats/${chatId}/unpack/hot-content/${hotContentId}`,
      {},
    );
  },
  selectHotContentType: async (chatId: string, hotContentId: string) => {
    return await privateApi.post(
      `/chats/${chatId}/hot-content/${hotContentId}`,
      {},
    );
  },
  buyHotContent: async (hotContentId: string) => {
    return await privateApi.post(`/hot-content/${hotContentId}/buy`, {});
  },
};

const userService = {
  ...commonUserService,
  ...contentsUserService,
  ...dataUserService,
  ...mysteryUserService,
  ...chatSellTipsUserService,
  ...chatSellCustomTipsUserService,
  ...chatSellMysteryBoxUserService,
  ...chatSellRealGiftsUserService,
  ...chatSellOnlineGiftsUserService,
  ...chatSellScriptsUserService,
  ...chatSellVotesUserService,
  ...chatHotContentUserService,
};

export default userService;
